import React from "react"
import { AuthenticateBox, CustomHelmet } from "@/components/common"
import { LoginForm } from "@/components/common/LoginForm"
import { BaseLayout } from "@/layouts/BaseLayout"

const Login = () => {
    return (
        <BaseLayout>
            <CustomHelmet title="login" />
            <AuthenticateBox>
                <LoginForm />
            </AuthenticateBox>
        </BaseLayout>
    )
}

export default Login
